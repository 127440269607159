import {
  Link,
  Switch,
  Route
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import './Body.scss';
import { ChevronDownIcon, WindowRestoreIcon } from '@fluentui/react-northstar';
/* import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faXing,
} from "@fortawesome/free-brands-svg-icons"; */
import { faProjectDiagram, faCity, faUsers, faIdCard, faHandshake, faStamp, faBusinessTime } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Body = (props: any) => {

  const [submenus, setSubmenus] = useState<{ masterdata: boolean, projectdata: boolean }>({ masterdata: false, projectdata: false });
  const [MD, setMD] = useState<boolean>(false);
  const [PR, setPR] = useState<boolean>(false);

  useEffect(() => {
  }, [props.open, MD, PR]);

  useEffect(() => {
  }, [submenus.masterdata, submenus.projectdata]);

  useEffect(() => {
    setSubmenus({
      masterdata: false,
      projectdata: false
    });
  }, []);

  const mdSub = (() => {
    return (<>
      <li className="sub"><Link to="/pdm/bp"><FontAwesomeIcon icon={faIdCard} /> <span>Geschäftspartner</span></Link></li>
      <li className="sub"><Link to="/pdm/masterdata/costcenter"><FontAwesomeIcon icon={faIdCard} /> <span>Kostenstellen</span></Link></li>
      <li className="sub"><Link to="/pdm/masterdata/bpgroups"><FontAwesomeIcon icon={faIdCard} /> <span>Benutzergruppen</span></Link></li></>);
  });

  const prSub = (() => {
    return (<>
      <li className="sub"><Link to="/pdm"><FontAwesomeIcon icon={faProjectDiagram} /> <span>Projektübersicht</span></Link></li>
      <li className="sub"><Link to="/pdm/projects"><FontAwesomeIcon icon={faProjectDiagram} /> <span>Projektsuche</span></Link></li>
      <li className="sub"><Link to="/pdm/project"><FontAwesomeIcon icon={faProjectDiagram} /> <span>Project</span></Link></li>
      </>);
  });

  const masterdataSubmenu = (() => {
    return (<>{submenus?.masterdata ? mdSub() : <></>}</>);
  });

  const projectdataSubmenu = (() => {
    return (<>{submenus?.projectdata ? prSub() : <></>}</>);
  });

  return (
    <div className="main">
      <div className={props.open ? '' : 'icon-only-nav'}>
        <aside>
          <Switch>
            <Route exact path="/">
              <ul>
                <li><Link to="/"><FontAwesomeIcon icon={faUsers} /> <span>TEAMS NAVIGATION</span></Link></li>
              </ul>
            </Route>
            <Route path="/pdm">
              <ul>
                <li className={!props.open ? 'with-submenu' : ''}>
                  <Link to="/pdm/"><FontAwesomeIcon icon={faCity} /> <span>Projekte</span></Link> <div onClick={() => { submenus.projectdata = !submenus.projectdata; setSubmenus(submenus); setMD(!MD); }}><ChevronDownIcon /></div>
                  {!props.open ? <ul>{prSub()}</ul> : ''}
                </li>
                {props.open ? projectdataSubmenu() : ''}
                <li className={!props.open ? 'with-submenu' : ''}>
                  <Link to="/pdm/masterdata/"><FontAwesomeIcon icon={faIdCard} /> <span>Stammdaten</span></Link> <div onClick={() => { submenus.masterdata = !submenus.masterdata; setSubmenus(submenus); setPR(!PR); }}><ChevronDownIcon /></div>
                  {!props.open ? <ul>{mdSub()}</ul> : ''}
                </li>
                {props.open ? masterdataSubmenu() : ''}
              </ul>
            </Route>
            <Route exact path="/kumat">
              <ul>
                <li><Link to="/kumat"><FontAwesomeIcon icon={faHandshake} /> <span>KUMAT</span></Link></li>
              </ul>
            </Route>
            <Route exact path="/contact">
              <ul>
                <li><Link to="/contact"><FontAwesomeIcon icon={faStamp} /> <span>CONTRACT</span></Link></li>
              </ul>
            </Route>
            <Route exact path="/time-recording">
              <ul>
                <li><Link to="/time-recording"><FontAwesomeIcon icon={faBusinessTime} /> <span>TIME RECORDING</span></Link></li>
              </ul>
            </Route>
            <Route exact path="/score">
              <ul>
                <li><Link to="/score"><WindowRestoreIcon /> <span>SCORE</span></Link></li>
              </ul>
            </Route>
          </Switch>
        </aside>
      </div>
      <main>
        {props.content}
      </main>
    </div>
  );
};
