import './Project.scss';

/**
 * Implementation of the KUMA Tab content page
 */
export const Project = () => {

  /**
   * The render() method to create the UI of the tab
   */
  return (
    <>
      <div className="project">
        <ul className="progress">
          <li>Step 1</li>
          <li className="active">Step 2</li>
          <li>Step 3</li>
          <li>Step 4</li>
        </ul>
      </div>
    </>
  );
};
