import './Header.scss';
import {
  Link
} from 'react-router-dom';
import { Input, Menu } from '@fluentui/react-northstar';
import { MenuIcon, MoreIcon, SearchIcon, WindowRestoreIcon } from '@fluentui/react-icons-northstar';
import { faCity, faUsers, faHandshake, faStamp, faBusinessTime } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Header = (props: any) => {

  const menuItems = [
    {
      key: 'edit',
      content: 'Editieren',
    },
    {
      key: 'events',
      content: <MoreIcon />,
      menu: {
        items: [
          {
            key: '1',
            content: <Link to="/"><FontAwesomeIcon icon={faUsers} /> <span>TEAMS NAVIGATION</span></Link>,
          },
          {
            key: '2',
            content: <Link to="/pdm"><FontAwesomeIcon icon={faCity} /> <span>PDM</span></Link>,
          },
          {
            key: '3',
            content: <Link to="/kumat"><FontAwesomeIcon icon={faHandshake} /> <span>KUMAT</span></Link>,
          },
          {
            key: '4',
            content: <Link to="/contact"><FontAwesomeIcon icon={faStamp} /> <span>CONTRACT</span></Link>,
          },
          {
            key: '5',
            content: <Link to="/time-recording"><FontAwesomeIcon icon={faBusinessTime} /> <span>TIME RECORDING</span></Link>,
          },
          {
            key: '6',
            content: <Link to="/score"><WindowRestoreIcon /> <span>SCORE</span></Link>,
          },
        ],
      },
    },
  ];

  return (
    <header className="header">
      <div className="sidebar-button">
        <button onClick={() => { props.handleSidebarState(); } }><MenuIcon /></button>
      </div>
      <div className="search">
        <Input icon={<SearchIcon />} placeholder="Suchen..." />
      </div>
      <div className="navigation-bar">
        <nav>
          <Menu defaultActiveIndex={0} underlined items={menuItems} primary />
        </nav>
      </div>
    </header>
  );
};
