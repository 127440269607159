import "./Home.scss";
import { Input } from '@fluentui/react-northstar'
import { SearchIcon } from '@fluentui/react-icons-northstar'
import { Link } from "react-router-dom";

export function Home(props: { showFunction?: boolean; environment?: string }) {

  const data = [{
    links: [{
      href: "#",
      text: "Link"
    }, {
      href: "#",
      text: "Link"
    }, {
      href: "#",
      text: "Link"
    }, {
      href: "#",
      text: "Link"
    }, {
      href: "#",
      text: "Link"
    }]
  }, {
    title: 'Gremien und Compliance',
    links: [{
      href: "#",
      text: "Link"
    }, {
      href: "#",
      text: "Link"
    }, {
      href: "#",
      text: "Link"
    }, {
      href: "#",
      text: "Link"
    }, {
      href: "#",
      text: "Link"
    }]
  }, {
    title: 'Abteilungen und Personen',
    links: [{
      href: "#",
      text: "Link"
    }, {
      href: "#",
      text: "Link"
    }, {
      href: "#",
      text: "Link"
    }, {
      href: "#",
      text: "Link"
    }, {
      href: "#",
      text: "Link"
    }]
  }, {
    title: 'Abteilungen und Personen',
    links: [{
      href: "#",
      text: "Link"
    }, {
      href: "#",
      text: "Link"
    }, {
      href: "#",
      text: "Link"
    }, {
      href: "#",
      text: "Link"
    }, {
      href: "#",
      text: "Link"
    }]
  }];

  return (<>
    { data && <div className="home">
      {
        data.map((el, index) => {
          return (
            <div key={index}>
              { el.title && <div className="home-header">
                <h4>{el.title}</h4>
              </div>}
              <div className="home-body">
                {el.title && <Input icon={<SearchIcon />} placeholder="Suchen..." fluid />}
                <div>
                  {el.links.map((l, i) => {
                    return <Link to="#" key={index + '' + i}>Link</Link>;
                  })
                  }
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
    }
  </>
  );
}
