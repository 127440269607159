import React from "react";
// https://fluentsite.z22.web.core.windows.net/quick-start
import { Provider, teamsTheme, Loader } from "@fluentui/react-northstar";
import { HashRouter as Router, Redirect, Route } from "react-router-dom";
import { useTeamsFx } from "./sample/lib/useTeamsFx";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import Tab from "./Tab";
import { PDM } from "../pages/PDM";
import { ProjectSearch } from "../pages/ProjectSearch";
import "./App.css";
import TabConfig from "./TabConfig";
import { Layout } from "./layout/Layout";
import { Masterdata } from "../pages/Masterdata";
import { Kumat } from "../pages/Kumat";
import { BP } from "../pages/BP";
import { Costcenter } from "../pages/Costcenter";
import { BPGroups } from "../pages/bpgroups";
import { BusinessPartner } from "../pages/BusinessPartner";
import { Project } from "../pages/Project";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { theme, loading } = useTeamsFx();
  return (
    <div className="kcenter">
      <Provider theme={theme || teamsTheme}>
        <Router>
          <Route exact path="/">
            <Redirect to="/tab" />
          </Route>
          {loading ? (
            <Loader style={{ margin: 100 }} />
          ) : (
            <Layout body={
              <>
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/termsofuse" component={TermsOfUse} />
                <Route exact path="/tab" component={Tab} />
                <Route exact path="/pdm" component={PDM} />
                <Route exact path="/pdm/projects" component={ProjectSearch} />
                <Route exact path="/pdm/bp" component={BusinessPartner} />
                <Route exact path="/pdm/project" component={Project} />
                <Route exact path="/pdm/masterdata" component={Masterdata} />
                <Route exact path="/pdm/masterdata/bp" component={BP} />
                <Route exact path="/pdm/masterdata/costcenter" component={Costcenter} />
                <Route exact path="/pdm/masterdata/bpgroups" component={BPGroups} />
                <Route exact path="/kumat" component={Kumat} />
                <Route exact path="/config" component={TabConfig} />
              </>} />
          )}
        </Router>
      </Provider>
    </div>
  );
}
