import './Documents.scss';
import { Table } from '@fluentui/react-northstar';
import { useEffect, useState } from 'react';

/**
 * Implementation of the KUMA Tab content page
 */
export const Documents = () => {

  const [showDetails, setShowDetails] = useState<boolean>(false);

  useEffect(() => {

  }, [showDetails]);

  const header = {
    items: ['Typ', 'Vorname', 'Nachname', 'Titel', 'Link', 'Text'],
  };

  const rowsPlain = [
    {
      key: 1,
      items: ['Debitor', 'Max', 'Mustermann', '', '', ''],
    },
  ];

  /**
   * The render() method to create the UI of the tab
   */
  return (
    <>
      <div className="bp-items-info">
        <h1>Dokumente</h1>
      </div>
    </>
  );
};
