import './BusinessPartner.scss';
import { useState, useEffect } from 'react';
import { GeneralInfos } from './prozesses/GeneralInfo';
import { Addresses } from './prozesses/Addresses';
import { Persons } from './prozesses/Persons';
import { Processes as Process } from './prozesses/Processes';
import { Link } from 'react-router-dom';
import { Documents } from './prozesses/Documents';

/**
 * Implementation of the KUMA Tab content page
 */
export const BusinessPartner = () => {

  const [TabSelection, setTabSelection] = useState<number>();

  useEffect(() => {
    setTabSelection(1);
  }, []);

  /**
   * The render() method to create the UI of the tab
   */
  return (
    <div className="prozesses">
      <div className="bp-header-title">
        <h1>
          <label>Partner</label><div>Max Mustermann</div>
        </h1>
      </div>
      <div className="bp-header-info">
        <div>
          <div>
            <label>Email:</label>
            <div>max.mustermann@beispiel.ch</div>
          </div>
          <div>
            <label>Mobil:</label>
            <div><Link to="#">+41 42 321 43 2</Link></div>
          </div>
        </div>
        <div>
          <div>
            <label>Rechnungsadresse:</label>
            <div>Bahnhofstr. 1, CH-8001 Zürich</div>
          </div>
          <div>
            <label>Lieferadresse:</label>
            <div>Bahnhofstr. 1, CH-8001 Zürich</div>
          </div>
        </div>
        <div>
          <label>Art</label>
          <div>Debitor</div>
        </div>
      </div>
      <div className="bp-body">
        <div className="tab-menu">
          <button className={ TabSelection === 1 ? "active" : ""} onClick={() => { setTabSelection(1); }}><span>Allgemein</span></button>
          <button className={ TabSelection === 2 ? "active" : ""} onClick={() => { setTabSelection(2); }}><span>Adressen</span></button>
          <button className={ TabSelection === 3 ? "active" : ""} onClick={() => { setTabSelection(3); }}><span>Personen</span></button>
          <button className={ TabSelection === 4 ? "active" : ""} onClick={() => { setTabSelection(4); }}><span>Dokumente</span></button>
          <button className={ TabSelection === 5 ? "active" : ""} onClick={() => { setTabSelection(5); }}><span>Prozesse</span></button>
        </div>
        <div className="bp-content">
          { TabSelection === 1 ? <GeneralInfos /> : <></> }
          { TabSelection === 2 ? <Addresses /> : <></> }
          { TabSelection === 3 ? <Persons /> : <></> }
          { TabSelection === 4 ? <Documents /> : <></> }
          { TabSelection === 5 ? <Process /> : <></> }
        </div>
      </div>
    </div>
  );
};
