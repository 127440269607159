import './bpgroups.scss';
import { BusinessPartner } from '../models/BusinessPartner';
import { Table } from '@fluentui/react-northstar';
import { useState, useEffect } from 'react';

/**
 * Implementation of the KUMA Tab content page
 */
export const BPGroups = () => {

  const [BP, setBP] = useState<BusinessPartner>();

  useEffect(() => {
    fetch('https://kuma-apps.azurewebsites.net/api/pdm/businesspartners/425f26e1-6791-40ab-8eb9-8a56ca67aa7c')
      .then(res => res.json())
      .then(
        (res) => {
          setBP(res);
        },
        (error) => {
          console.dir(error);
        }
      );
  }, []);

  const header = {
    items: ['Firam', 'Anschprechpartner', 'Projekte', 'Adresse', 'Typ', 'Kunde seit', 'Region'],
  }
  const rows = [
    {
      key: 1,
      items: ['1', 'Roman van von der Longername', 'None', '30 years', '', '', ''],
    },
    {
      key: 2,
      items: ['2', 'Alex', 'None', '1 year', '', '', ''],
    },
    {
      key: 3,
      items: ['3', 'Ali', 'None', '30000000000000 years', '', '', ''],
    },
  ]

  /**
   * The render() method to create the UI of the tab
   */
  return (
    <>
      <div className="bpg">
        {BP && <div>
          <div><h4>Benutzergruppen</h4></div>
          <div>
            <Table header={header} rows={rows} aria-label="Static table" />
          </div>
        </div>}
      </div>
    </>
  );
};
