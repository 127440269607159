import { Culture } from '../interfaces/iCulture';

const locales: { [key:string]: Culture; } = sessionStorage.getItem('i18n') ? JSON.parse(sessionStorage.getItem('i18n') + '') : {};

export const getTranslationsByLang = async (culture: string = 'deCH') => {
  await fetch(`/assets/locales/${culture}.json`)
    .then((res) => res.json())
    .then(
      (res) => {
        locales[culture] = locales[culture] ? locales[culture] : {};
        locales[culture].translations = res;
        sessionStorage.setItem('i18n', JSON.stringify(locales));
        sessionStorage.setItem('culture', culture);
      },
      (error) => {
        console.dir(error);
      }
    );
};

export const translate = (key: string) => {
  const culture: string = sessionStorage.getItem('culture') ? sessionStorage.getItem('culture') + '' : 'deCH';
  const locale = locales[culture] ? locales[culture] : { };
  const translations = locale.translations ? locale.translations : {};
  return translations[key] ? translations[key] : '';
};

export default translate;
