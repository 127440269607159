import './ProjectSearch.scss';
/* import t from '../core/i18n'; */
import { BusinessPartner } from '../models/BusinessPartner';
import { Table, Menu } from '@fluentui/react-northstar';
import { useState, useEffect } from 'react';

/**
 * Implementation of the KUMA Tab content page
 */
export const ProjectSearch = () => {

  const [BP, setBP] = useState<BusinessPartner>();

  useEffect(() => {
    fetch('https://kuma-apps.azurewebsites.net/api/pdm/businesspartners/425f26e1-6791-40ab-8eb9-8a56ca67aa7c')
      .then(res => res.json())
      .then(
        (res) => {
          setBP(res);
        },
        (error) => {
          console.dir(error);
        }
      );
  }, []);

  const items = [
    {
      key: 'New',
      content: 'Neu',
    },
    {
      key: 'Recent',
      content: 'Zuletzt bearbeitet',
    },
    {
      key: 'All',
      content: 'Alle',
    },
    {
      key: 'Sale',
      content: 'Verkaufsphase',
    },
    {
      key: 'Action',
      content: 'Ausführungsphase',
    },
    {
      key: 'Service',
      content: 'Servicephase',
    },
    {
      key: 'Fin',
      content: 'Abgeschlossene Projekte',
    },
  ]

  const header = {
    items: ['Projekt', 'Projektbeschreibung', 'Kunde', 'Phase','Projekttyp', 'Kostenstelle', 'Region'],
  }
  const rows = [
    {
      key: 1,
      items: ['1', 'Roman van von der Longername', 'None', '30 years','','',''],
    },
    {
      key: 2,
      items: ['2', 'Alex', 'None', '1 year','','',''],
    },
    {
      key: 3,
      items: ['3', 'Ali', 'None', '30000000000000 years','','',''],
    },
  ]
  
  /**
   * The render() method to create the UI of the tab
   */
  return (
    <>
      {BP && <div className="x1">
        <div><Menu defaultActiveIndex={2} items={items} pointing primary /></div>
        <div><h4>Zuletzt bearbeitete Projekte</h4></div>
        <div>
          <Table header={header} rows={rows} aria-label="Static table" />
        </div>
      </div>}
    </>
  );
};
