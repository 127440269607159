import './Kumat.scss';
import { BusinessPartner } from '../models/BusinessPartner';
import { useState, useEffect } from 'react';
import { Menu, Table } from '@fluentui/react-northstar';

/**
 * Implementation of the KUMA Tab content page
 */
export const Kumat = () => {

  const [BP, setBP] = useState<BusinessPartner>();

  useEffect(() => {
    fetch('https://kuma-apps.azurewebsites.net/api/pdm/businesspartners/425f26e1-6791-40ab-8eb9-8a56ca67aa7c')
      .then(res => res.json())
      .then(
        (res) => {
          setBP(res);
        },
        (error) => {
          console.dir(error);
        }
      );
  }, []);

  const header = {
    items: ['Item', 'Material', 'Batch', 'Plant', 'Storage Location', 'Actual Delivery Quality', 'Picking Qualitiy'],
  };

  const rowsPlain = [
    {
      key: 1,
      items: ['10', 'Trading Good Reorder', 'None', 'Plant 1 (54684)', ' Std. storage 1', '120 000 PC1', '120 000 PC'],
    },
    {
      key: 2,
      items: ['10', 'Trading Good Reorder', 'None', 'Plant 1 (54684)', ' Std. storage 1', '120 000 PC2', '120 000 PC'],
    },
    {
      key: 3,
      items: ['10', 'Trading Good Reorder', 'None', 'Plant 1 (54684)', ' Std. storage 1', '120 000 PC3', '120 000 PC'],
    },
  ];

  const items = [
    {
      key: 'generalInformations',
      content: 'General Informations',
    },
    {
      key: 'items',
      content: 'Items',
    },
    {
      key: 'businesspartners',
      content: 'Businesspartners',
    },
    {
      key: 'processFlow',
      content: 'Process flow',
    },
  ];

  /**
   * The render() method to create the UI of the tab
   */
  return (
    <>
      {BP && <div className="kumat">
        <div className="kumat-header-title">
          <h1>
            <label>Delivery</label><div>80001184</div>
          </h1>
        </div>
        <div className="kumat-header-info">
          <div>
            <div>
              <label>Ship-To-Party:</label>
              <div>Computer-Systems(MY)(3552158421)</div>
            </div>
            <div>
              <label>Sold-To-Party:</label>
              <div>Computer-Systems(MY)(3552158421)</div>
            </div>
          </div>
          <div>
            <div>
              <label>Overall Status:</label>
              <div>Completed</div>
            </div>
            <div>
              <label>Shipping Point</label>
              <div>Shipping Point (514486)</div>
            </div>
          </div>
          <div>
            <label>Delivery Date:</label>
            <div>10/06/2016</div>
          </div>
        </div>
        <div className="kumat-body">
          <Menu defaultActiveIndex={0} items={items} underlined primary />
          <div className="kumat-content">
            <div className="kumat-body-info">
              <div>
                <div>
                  <label>Sales Organisation:</label>
                  <div>Dom Sales Org(654815)</div>
                </div>
                <div>
                  <label>Gross Wheight:</label>
                  <div>200 000 KG</div>
                </div>
              </div>
              <div>
                <div>
                  <label>Picking Date:</label>
                  <div>10/04/2016</div>
                </div>
                <div>
                  <label>Transport Planning Date:</label>
                  <div>10/04/2016</div>
                </div>
              </div>
              <div>
                <div>
                  <label>Picking Status:</label>
                  <div>Fully picked(C)</div>
                </div>
                <div>
                  <label>Warehouse Management Status:</label>
                  <div>No WM trnsf ord reqd</div>
                </div>
              </div>
            </div>
            <div className="kumat-items-info">
              <h1>Items</h1>
              <Table compact header={header} rows={rowsPlain} aria-label="Compact view static table" />
            </div>
            <div className="kumat-bp-info">
              <h1>Businesspartner</h1>
              <div className="kumat-bp-list">
                <div>
                  <div></div>
                  <div>
                    <label>Computer Systems</label>
                    <div>Sold-To-Party</div>
                  </div>
                </div>
                <div>
                  <div></div>
                  <div>
                    <label>Computer Systems</label>
                    <div>Sold-To-Party</div>
                  </div>
                </div>
                <div>
                  <div></div>
                  <div>
                    <label>Computer Systems</label>
                    <div>Sold-To-Party</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};
