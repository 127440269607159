import './GeneralInfos.scss';
import { Table } from '@fluentui/react-northstar';
import { useEffect, useState } from 'react';

/**
 * Implementation of the KUMA Tab content page
 */
export const Persons = () => {

  const [showDetails, setShowDetails] = useState<boolean>(false);

  useEffect(() => {

  }, [showDetails]);

  const header = {
    items: ['Typ', 'Vorname', 'Nachname', 'Titel', 'Link', 'Text'],
  };

  const rowsPlain = [
    {
      key: 1,
      items: ['Debitor', 'Max', 'Mustermann', '', '', ''],
    },
  ];

  /**
   * The render() method to create the UI of the tab
   */
  return (
    <>
      { showDetails ? <>
        <div className="options-bar"><button onClick={(e: any) => {
          e.persist();
          setShowDetails(false);
        }}><span>Zurück</span></button></div>
        <div className="bp-body-info">
          <div>
            <div>
              <label>Vorname:</label>
              <div>Max</div>
            </div>
            <div>
              <label>Nachname:</label>
              <div>Mustermann</div>
            </div>
          </div>
          <div>
            <div>
              <label>Mobile:</label>
              <div>+41 43 423 54 3</div>
            </div>
            <div>
              <label>Email:</label>
              <div>max.mustermann@beispiel.ch</div>
            </div>
          </div>
          <div>
            <div>
              <label>Position:</label>
              <div>CEO</div>
            </div>
            <div>
              <label>Priorisierung:</label>
              <div>2</div>
            </div>
          </div>
        </div></> : <></>}
      { !showDetails ? <div className="bp-items-info">
        <h1>Geschäftsbeziehungen</h1>
        <Table onClick={(e: any) => {
          e.persist();
          setShowDetails(true);

        }} compact header={header} rows={rowsPlain} aria-label="Compact view static table" />
      </div> : <></>}
    </>
  );
};
