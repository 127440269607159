import './PDM.scss';
import { BusinessPartner } from '../models/BusinessPartner';
import { Table } from '@fluentui/react-northstar';
import { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';

/**
 * Implementation of the KUMA Tab content page
 */
export const PDM = () => {

  const [BP, setBP] = useState<BusinessPartner>();

  useEffect(() => {
    fetch('https://kuma-apps.azurewebsites.net/api/pdm/businesspartners/425f26e1-6791-40ab-8eb9-8a56ca67aa7c')
      .then(res => res.json())
      .then(
        (res) => {
          setBP(res);
        },
        (error) => {
          console.dir(error);
        }
      );
  }, []);

  const data = {
    labels: ['Offen', 'Gewonnen', 'Verloren'],
    title: 'Angebote',
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3],
        backgroundColor: [
          'rgba(238, 230, 114, 0.2)',
          'rgba(114, 238, 155, 0.2)',
          'rgba(238, 114, 114, 0.2)',
        ],
        borderColor: [
          'rgba(173, 163, 76, 1)',
          'rgba(76, 173, 108, 1)',
          'rgba(173, 76, 76, 1)',
        ],
        borderWidth: 1,
      },
    ],
    options: {
      plugins: {
        datalabels: {
          display: true,
          align: 'bottom',
          backgroundColor: '#fff',
          borderRadius: 3,
          formatter: (value: string) => {
            return value + '%';
          },
          font: {
            size: 18,
          }
        },
      }
    }
  };

  const header = {
    items: ['Item', 'Material', 'Batch', 'Plant', 'Storage Location', 'Actual Delivery Quality', 'Picking Qualitiy'],
  };

  const rowsPlain = [
    {
      key: 1,
      items: ['10', 'Trading Good Reorder', 'None', 'Plant 1 (54684)', ' Std. storage 1', '120 000 PC1', '120 000 PC'],
    },
    {
      key: 2,
      items: ['10', 'Trading Good Reorder', 'None', 'Plant 1 (54684)', ' Std. storage 1', '120 000 PC2', '120 000 PC'],
    },
    {
      key: 3,
      items: ['10', 'Trading Good Reorder', 'None', 'Plant 1 (54684)', ' Std. storage 1', '120 000 PC3', '120 000 PC'],
    },
  ];

  /**
   * The render() method to create the UI of the tab
   */
  return (
    <>
      {BP && <div className="x0">
        <div>
          <div>
            <div><Doughnut type="" data={data} /></div>
            <div>
              <h3>Ausschreibungen</h3>
              <div>Laufende (5231)</div>
              <div>Historische (182015)</div>
            </div>
            <div>
              <div>Produkte (552)</div>
              <div>Varianten (130)</div>
            </div>
          </div>
          <div>
            <Table compact header={header} rows={rowsPlain} aria-label="Compact view static table" />
          </div>
        </div>
      </div>}
    </>
  );
};
