import './GeneralInfos.scss';
import { Table } from '@fluentui/react-northstar';
import { useEffect, useState } from 'react';

/**
 * Implementation of the KUMA Tab content page
 */
export const GeneralInfos = () => {

  const [showDetails, setShowDetails] = useState<boolean>(false);

  useEffect(() => {

  }, [showDetails]);

  const header = {
    items: ['Firma', 'UID-Register', 'Status', 'Anzahl Projekte', 'Laufende Projekte', 'Priorisierung'],
  };

  const rowsPlain = [
    {
      key: 1,
      items: ['10', 'CHE-112.392.990', 'Aktiv', '11', ' 8', '2'],
    },
  ];

  /**
   * The render() method to create the UI of the tab
   */
  return (
    <>
    { showDetails ? <>
        <div className="options-bar"><button onClick={(e: any) => {
          e.persist();
          setShowDetails(false);
        }}><span>Zurück</span></button></div>
      <div className="bp-body-info">
        <div>
          <div>
            <label>Firma:</label>
            <div>Big Bang AG</div>
          </div>
          <div>
            <label>Unternehmens-ID:</label>
            <div>CHE-112.392.990</div>
          </div>
        </div>
        <div>
          <div>
            <label>Status:</label>
            <div>Aktiv</div>
          </div>
          <div>
            <label>Anzahl Projekte:</label>
            <div>11</div>
          </div>
        </div>
        <div>
          <div>
            <label>Laufende Projekte:</label>
            <div>8</div>
          </div>
          <div>
            <label>Priorisierung:</label>
            <div>2</div>
          </div>
        </div>
      </div></> : <></> }
      { !showDetails ? <div className="bp-items-info">
        <h1>Geschäftsbeziehungen</h1>
        <Table onClick={(e: any)=>{
          e.persist();
          setShowDetails(true);
          
          }} compact header={header} rows={rowsPlain} aria-label="Compact view static table" />
      </div> : <></>}
    </>
  );
};
