import './GeneralInfos.scss';
import { Table } from '@fluentui/react-northstar';
import { useEffect, useState } from 'react';

/**
 * Implementation of the KUMA Tab content page
 */
export const Addresses = () => {

  const [showDetails, setShowDetails] = useState<boolean>(false);

  useEffect(() => {

  }, [showDetails]);

  const header = {
    items: ['Strasse', 'Hausnummer', 'Postleitzahl', 'Stadt', 'Land'],
  };

  const rowsPlain = [
    {
      key: 1,
      items: ['Bahnhofstr', '49', '8008', 'Zürich', ' Schweiz',],
    },
  ];

  /**
   * The render() method to create the UI of the tab
   */
  return (
    <>
      { showDetails ? <>
        <div className="options-bar"><button onClick={(e: any) => {
          e.persist();
          setShowDetails(false);
        }}><span>Zurück</span></button></div>
          <div className="bp-body-info">
            <div>
              <div>
                <label>Lieferadresse:</label>
                <div>Bahnhofstr. 1 CH-8001 Zürich</div>
              </div>
            </div>
          </div></> : <></>}
        { !showDetails ? <div className="bp-items-info">
          <h1>Adressen</h1>
          <Table onClick={(e: any) => {
            e.persist();
            setShowDetails(true);

          }} compact header={header} rows={rowsPlain} aria-label="Compact view static table" />
        </div> : <></>}
      </>
  );
};
