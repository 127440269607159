import './GeneralInfos.scss';
import { Table } from '@fluentui/react-northstar';

/**
 * Implementation of the KUMA Tab content page
 */
export const Processes = () => {

  const header = {
    items: ['Typ', 'Prozess', 'Status', 'Link', 'Text'],
  };

  const rowsPlain = [
    {
      key: 1,
      items: ['10', 'Trading Good Reorder', 'None', '120 000 PC1', '120 000 PC'],
    },
    {
      key: 2,
      items: ['10', 'Trading Good Reorder', 'None', '120 000 PC2', '120 000 PC'],
    },
    {
      key: 3,
      items: ['10', 'Trading Good Reorder', 'None', '120 000 PC3', '120 000 PC'],
    },
  ];

  /**
   * The render() method to create the UI of the tab
   */
  return (
    <>
      <div className="bp-items-info">
        <h1>Prozesse</h1>
        <Table compact header={header} rows={rowsPlain} aria-label="Compact view static table" />
      </div>
    </>
  );
};
