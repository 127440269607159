import * as React from 'react';
import './Layout.scss';
import { useState, useEffect } from 'react';
import { Header } from './Header';
import { getTranslationsByLang } from '../../core/i18n';
import { Body } from './Body';

export const Layout = ({ body }: any) => {

  const [openSidebar, setOpenSidebar] = useState<boolean>(true);
  const [tranlations, setTranslations] = useState<boolean>();

  const handleSidebarState = () => {
    const sidebar = sessionStorage.getItem('openSidebar') === 'true';
    setTimeout(() => {
      setOpenSidebar(!sidebar);
      sessionStorage.setItem('openSidebar', JSON.stringify(!sidebar));
    }, 500);
  };

  useEffect(() => {
    if (!sessionStorage.getItem('i18n')) {
      getTranslationsByLang().then(() => {
        setTranslations(true);
      });
    } else {
      const i18n = JSON.parse(sessionStorage.getItem('i18n') + '');
      if (!i18n[sessionStorage.getItem('culture') + '']) {
        getTranslationsByLang().then(() => {
          setTranslations(true);
        });
      }
    }
  }, [openSidebar, tranlations]);

  return (
    <div className="layout">
      <Header handleSidebarState={handleSidebarState}></Header>
      <Body open={openSidebar} handleSidebarState={handleSidebarState} content={body}></Body>
    </div>
  );
};
